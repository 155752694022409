import React from 'react';


import bg1 from '../assets/splash/aleksandr-ledogorov-G-JJy-Yv_dA-unsplash.jpg';
import bg2 from '../assets/splash/andre-benz-sLokLIacItI-unsplash.jpg';
import bg3 from '../assets/splash/colton-duke-QRU0i5AqEJA-unsplash.jpg';
import bg4 from '../assets/splash/daniele-levis-pelusi-Ar5FGfHXwMU-unsplash.jpg';
import bg5 from '../assets/splash/harli-marten-n7a2OJDSZns-unsplash.jpg';
// import bg6 from '../assets/splash/jordan-mcdonald-Bzd1qPySNvk-unsplash.jpg';
import bg7 from '../assets/splash/josh-felise-ZBD7Wh3SJEI-unsplash.jpg';
import bg8 from '../assets/splash/kien-do-NjT4O7WYmwk-unsplash.jpg';
// import bg9 from '../assets/splash/masaaki-komori-7xP5BJ34ybg-unsplash.jpg';
import bg10 from '../assets/splash/paul-e-harrer-AJqHzUbc1io-unsplash.jpg';
import bg11 from '../assets/splash/v2osk-d-OQYiy1gQo-unsplash.jpg';
// Jordan and Skye whatever - not liking
// import bg6 from '../assets/splash/jordan-mcdonald-Bzd1qPySNvk-unsplash.jpg';
// import bg9 from '../assets/splash/masaaki-komori-7xP5BJ34ybg-unsplash.jpg';

const backgrounds = [ bg1, bg2, bg3, bg4, bg5, bg7, bg8, bg10, bg11  ];
const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];

let bgImgElement = null;


export default function RandomBackgroundWrapper({ children }) {
	const [ bgUrl, setBgUrl ] = React.useState("");
	const [ bgLoadStarted, setBgLoadStarted ] = React.useState(false);

	if(!bgUrl && !bgLoadStarted) {
		setBgLoadStarted(true);
		if(bgImgElement) {
			setTimeout(() => 
				setBgUrl(bgImgElement.src), 1);
		} else {
			// Preload the background image before rendering
			// so it doesn't render partially as it downloads
			bgImgElement = document.createElement('img');
			bgImgElement.onload = () => setBgUrl(bgImgElement.src);
			bgImgElement.src = randomBackground;
		}
	}

	return (<>
		<div style={{
			backgroundImage: `url(${bgUrl})`,
			background: 'no-repeat center center fixed', 
			backgroundSize: 'cover',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start',
			height: '100%',
		}}>
			{children}
		</div>
	</>);
}