import React from 'react';

import styles from './SharePage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import TextareaAutosize from 'react-autosize-textarea';

import { ServerStore } from 'utils/ServerStore';
import { useRemoteData, remoteDataCache } from 'utils/useRemoteData';

import LoadingSpinner from 'components/LoadingSpinner';
import useUpdateUi from '../../utils/useUpdateUi';

// Simple utility to take "foo/bar/baz" and make it "foo/bar"
const backUrl = (url="") => url.split('/').slice(0,-1).join('/');

export default function SharePage({ match, ...props }) {
	return (<>
		<Route exact path={match.path} component={SharePageHome}/>
		<Route path={`${match.path}/scenario/:scenarioId`} component={SharePageScenario}/>
	</>);
}

function SharePageScenario({ match, ...props }) {
	const { scenarioId } = match.params,
		updateUi = useUpdateUi(), // hook to force re-render
		scenario = useRemoteData(
			() => ServerStore.GetScenario(scenarioId),
			'GetScenario:' + scenarioId,
		);

	const addNewChoice = async () => {
		// opt = prompt("What do you want to improve?");
		const opt = (await window.Alert({
			title: 'What choice do you want to suggest?',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'true'
			},
			showCancelButton: true,
			confirmButtonText: 'Add Choice',
		})).value;
		
		if(opt) {
			
			const choice = await ServerStore.AddChoiceToScenario({
				name: opt,
				scenarioId
			});

			remoteDataCache['GetScenario:' + scenarioId].cacheDirty = true;
			// Socket SHOULD notify useRemoteData to reload

			if(choice instanceof Error) {
				window.Alert(choice.message);
			} else {
				console.log("[SharePageScenario] created choice:", choice);
			}
			updateUi(); // re-render to re-request data
		}
	}

	const voteForChoice = async choice => {
		const vote = await ServerStore.AddVoteToChoice({ choiceId: choice.id });
		if(vote instanceof Error) {
			window.Alert(vote.message);
		} else {
			remoteDataCache['GetScenario:' + scenarioId].cacheDirty = true;

			updateUi(); // re-render to re-request data
		}
		// socket event will be used to reload the list, no reloading needed here
	}

	// console.log("[SharePageScenario]", { scenarioId, scenario });
	// console.log("scenario:", scenario);
	return (<>

		<SubPageLayout title="Give Advice - Decidr">
			<div className={styles.scenarioRoot}>
	
				<Link to={'/share'}><FontAwesomeIcon icon={faArrowLeft}/></Link>

				{!scenario.id ? 
					<center>
						<LoadingSpinner/>
					</center>
				: <>

					{/* <h1>Scenario: [{match.params.scenarioId}]</h1> */}
					<h1>{scenario.name}</h1>

					<div className={styles.target}>
						<div className={styles.label}>You want to improve:</div>
						<div className={styles.value}>{scenario.targetGoal}</div>
					</div>

					<div className={styles.interactionListRoot}>
				
						{scenario.interactions.reverse().map(item =>
							<div key={item.id} className={styles.interactionRoot}>
								{item.status === 'DRAFT' ? <>
									<div className={styles.chocieListRoot}>
										{item.choices.map(choice => 
											<div key={choice.id} className={styles.choiceRoot}
												onClick={() => voteForChoice(choice)}>
												<span className={styles.choiceName}>
													{choice.name}
												</span>
												<span className={styles.votes}>
													{choice.voteCount > 0 ? <>{" "}({Math.ceil(choice.voteCount)})</> : ""}
												</span>
											</div>	
										)}
										<div className={styles.choiceRoot} onClick={() => addNewChoice()}
											style={{
												justifyContent: 'center'
											}}
										>
											<FontAwesomeIcon icon={faPlus}/>
										</div>
									</div>
									
								</> : <>
									Non-draft
								</>}
							</div>
						)}
					</div>
				</>}

			</div>

		</SubPageLayout>
	</>);
}


function SharePageHome({ match, ...props }) {

	const scenarios = useRemoteData(
		() => ServerStore.GetScenariosPendingAdvice(),
		'GetScenariosPendingAdvice'
	);

	console.log("[SharePageHome] GetScenariosPendingAdvice:", scenarios);

	// Advice loaded but empty? Send to "request" endpoint
	// if(!scenarios.length && scenarios.loadDone) {
	// 	setTimeout(() => 
	// 			props.history.push(`${match.url}/request`), 1);
	// }

	return (<>
		<SubPageLayout title="Give Advice - Decidr">
			<div className={styles.root}>

				<Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link>

				<h1>Give Advice</h1>

				{/* <Link to={`${match.url}/request`}
					className={styles.request}>
					<FontAwesomeIcon icon={faPlus}/>
				</Link> */}

				<ScenarioList scenarios={scenarios}/>

			</div>

		</SubPageLayout>
	</>);
}



function ScenarioList({ scenarios, ...props }) {
	if(!scenarios.loadDone) {
		return <center>
			<LoadingSpinner/>
		</center>;
	}

	if(!Array.isArray(scenarios))
		scenarios = Array.from(scenarios || []);

	return (<>
		<div className={styles.optionListRoot}>
			{scenarios.map(item =>
				<MyScenarioItem key={item.id} item={item}/>
			)}
		</div>
	</>)
}

function MyScenarioItem({ item, ...props }) {
	return (<>
		<Link to={`/share/scenario/${item.id}`} className={styles.optionRoot}>
			<div className={styles.title}>
				{item.name}
			</div>
			{/* <div className={styles.status}>
				{item.statusTitle}
			</div> */}
		</Link>
	</>)
}