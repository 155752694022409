import React from 'react';


import styles from './AboutPage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function AboutPage({ ...props }) {
	return (<>
		<SubPageLayout title="What is Decidr?">
			<div className={styles.root}>

				<Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link>

				<h1>What is Decidr?</h1>
				
				<p>Decidr lets you ask for advice anonymously: People offer suggestions to your questions, vote on suggestions, and you choose what to try to improve your scenario.</p>
				
				<p>After reporting back on how well the suggestions work, you can ask for more advice for the scenario, or mark it resolved!</p>
				
				<p>Decidr is an experiment in human psychology and decision making, studying how we give advice to each other and how artificial intelligence can learn from our decision making processes.</p>

				<p>When you give advice through Decidr, you are competing with the artificial intelligence to see if you can beat the machine. When you receive advice, you're telling the AI which suggestions are better, helping it to learn what works in different scenarios.</p>
				
				<p>Decidr was created by 
					{' '}
					<a href='mailto:josiahbryan+decidr@gmail.com'>Josiah Bryan</a> 
					{' '}
					<a href='https://instagram.com/josiahbryan'><FontAwesomeIcon icon={faInstagram}/></a>
				</p>
			</div>

		</SubPageLayout>
	</>);
}