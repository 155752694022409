// import LoginPage from 'pages/LoginPage';
import HomePage  from 'pages/HomePage/HomePage';
import SampleSubPage  from 'pages/SampleSubPage/SampleSubPage';
import AboutPage  from 'pages/AboutPage/AboutPage';
import AdvicePage  from 'pages/AdvicePage/AdvicePage';
import SharePage  from 'pages/SharePage/SharePage';

const Routes = {
	'/':    { component: HomePage, exact: true },
	'/advice': { component: AdvicePage } ,
	'/share': { component: SharePage } ,
	'/about': { component: AboutPage }
};

export default Routes;