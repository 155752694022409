import React from 'react';
import { ServerStore } from './ServerStore';

/*
	Custom hook for use with React Hooks and async data loading.

	Example usage:

		const advice = useRemoteData(() => ServerStore.getUserAdviceList());

*/

export const remoteDataCache = {};

export function useRemoteData(createPromiseCallback, socketEventName) {
	const [ remoteData, setRemoteData ] = React.useState({});

	// Reusable setter for below in order to handle array vs object datas
	const remoteDataLoaded = data => {
		if(Array.isArray(data)) {
			data.loadDone = true;
			setRemoteData(data);
		} else
		if(data) {
			setRemoteData({ 
				loadDone: true,
				...data
			});
		} else {
			setRemoteData({ 
				loadDone: true,
			});
		}
	}

	if(socketEventName && 
		remoteDataCache[socketEventName] &&
		remoteDataCache[socketEventName].cacheDirty &&
		remoteData.loadDone) {
		// Not using setRemoteData because the only reason we're
		// setting this flag is to cause it to enter the next block
		remoteData.loadDone = false;
		delete remoteDataCache[socketEventName];
	}

	if (!remoteData.loadStarted && 
		!remoteData.loadDone) {

		// Only cache data if socket event name is given,
		// uses ServerUtil.socket() to listen for updates
		if(socketEventName && 
			remoteDataCache[socketEventName]) {
			console.log("[useRemoteData] cache hit:", socketEventName);
			const data = remoteDataCache[socketEventName];
			remoteDataLoaded(data);
		} else {
			// No cache, so execute createPromiseCallback() to get the data
			// and cache if event given

			if(socketEventName)
				console.log("[useRemoteData] cache miss:", socketEventName);

			setRemoteData({ ...remoteData, loadStarted: true });

			createPromiseCallback().then(data => {
				remoteDataLoaded(data);

				// Cache data for subsequent use
				remoteDataCache[socketEventName] = data;

				if(socketEventName)
					console.log("[useRemoteData] cache loaded from server:", socketEventName);


				// console.log("Got remote data:", data);
			}).catch(error => {
				// console.error("Error loading remote data:", error);
				setRemoteData({
					loadDone: true,
					error
				})
			})
		}
	}

	// Effect is necessary to connect socket on mounting the component using this
	// hook and disconnect from the socket event when component is unmounted
	React.useEffect(() => {
		let callback;
		if(socketEventName) {
			// Connect to the socket and listen for updates
			// Use .onSocketEvent instead of .socket().on() because
			// we could be calling this before the socket is connected,
			// so .onSocketEvent buffers our handlers until the socket is connected.
			ServerStore.onSocketEvent(socketEventName, callback = data => {
				console.log("[useRemoteData] cache updated via socket:", socketEventName);

				remoteDataLoaded(data);
			});
		}

		// Return callback to disconnect from socket event
		// when component that is using this effect unmounts
		return () => {
			if(socketEventName) {
				// console.log("[useRemoteData] stopped listening on unmount:", socketEventName);

				ServerStore.offSocketEvent(socketEventName, callback);
			}
		}
	}, [ socketEventName ]);

	return remoteData;
};