import React from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route } from 'react-router-dom';

import Routes from 'pages';
import { ServerStore } from 'utils/ServerStore';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';

// Used by utils/normalize-link
window._reactRoutePrefix = '#';

// Add custom Alert as a react-enabled swal instance
window.Swal = Swal;
window.ReactSwal = withReactContent(Swal);
window.Alert = opts => window.ReactSwal.fire(opts);

export default function App() {
	setStatusBarColor('#000000'); // top color in gradient 
	ServerStore.metric("app.booted");
	return (<>
		<Router>
			{Object.keys(Routes).map(path => <Route 
				key       = {path}
				path      = {path}
				component = {Routes[path].component}
				exact     = {Routes[path].exact}/>
			)}
		</Router>
	</>);
}
