import React from 'react';
import styles from './HomePage.module.scss';
import Head from 'components/Head';

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { ServerStore } from 'utils/ServerStore';
import { useRemoteData } from 'utils/useRemoteData';

import RandomBackgroundWrapper from 'components/RandomBackgroundWrapper';


export default function HomePage({ ...props }) {


	const landingData = useRemoteData(() => ServerStore.GetUserLandingData());
	
	return (<>
		<Head title="Decidr"/>
		<RandomBackgroundWrapper>
			<div className={styles.root}>
				<div className={styles.centeringBlock}>
					<div className={styles.banner}>
						<span className={styles.d}>D</span>
						<span className={styles.ecidr}>ecidr</span>
					</div>
					<div className={styles.buttons}>
						<Link to="/advice">
							Get Advice 
							{landingData.responses > 0 ? <>({landingData.responses})</> : ""}
							<FontAwesomeIcon icon={faChevronRight}/>
						</Link>
						<Link to="/share">
							Give Advice
							{landingData.questions > 0 ? <>({landingData.questions})</> : ""}
							<FontAwesomeIcon icon={faChevronLeft}/>
						</Link>
					</div>
					<Link to="/about" className={styles.infoLink}>
						What is Decidr?
					</Link>
				</div>
			</div>
		</RandomBackgroundWrapper>
	</>);
}