import { server } from './ServerUtil';
import gtag from './GoogleAnalytics';
// import { Cat, Level } from './models';
import { EventEmitter } from 'events';
// import * as Sentry from '@sentry/browser';
import { DeviceInfo } from './DeviceInfo';
// import { avg } from './geom';
import { mixpanel } from './mixpanel';
import { isPhoneGap } from './isPhoneGap';

import io from 'socket.io-client';

// Load for ad conversion tracking
import './trackingPixels';

// NOTE: We include buildTime from a .json file instead of a simple .txt file because
// webpack will embed the JSON data at build time and so the bundle will end up with
// "buildTime={...}". If we had used a ".txt" file - while they are easier to generate
// in package.json, instead webpack would have given us a static assset URL, e.g.
// "buildTime='./static/assets/build-time.txt'" or something like that. We could fetch that,
// yes, but the purpose of the buildTime var is to indicate when the RUNNING SOFTWARE
// was built, NOT whatever is on the server. So, by embedding JSON in the bundle,
// we will "freeze" the time the bundle was built in stone inside the bundle itself,
// as buildTime.date (see package.json for how this file is generated)
import buildTime from './build-time.json';
// import { defer } from './defer';

// Disable for now until we set this up for this project
const Sentry = null;

// Must match what's used on the server - this is for Unicorn on FB
export const FB_APP_ID = "328677907799571";

// for reuse elsewhere, such as LoginScene
export { gtag };

// For local storage of FB token
const TOKEN_KEY     = 'fb-accessToken';

// For common definition
export const LOGIN_EVENT = "login-event";

// For common definition
export const APP_PAUSED_EVENT  = "app-paused-event";
export const APP_RESUMED_EVENT = "app-resumed-event";

// Hide Josiah from MixPanel (still log metrics to server though)
const HIDE_USER_ID_FROM_MIXPANEL = 3;


export class ServerStore {
	// Patch with info incase we boot before the script in index.html boots
	static isPhoneGap = isPhoneGap;

	// static currentAccount = null;
	static currentUser    = null;

	static _events = new EventEmitter();
	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}

	// static models() {
	// 	return { Cat, Level };
	// }

	static server() { return server };

	static async GetUserLandingData() {
		await  this.autoLogin();
		return this.server().get('/decidr/landing');
	}

	static async GetUserAdviceList() {
		await  this.autoLogin();
		return this.server().get('/decidr/advice');
	}

	static async GetScenario(scenarioId) {
		await  this.autoLogin();
		return this.server().get('/decidr/scenario', { scenarioId });
	}

	static async CreateScenario(params) {
		await  this.autoLogin();
		return this.server().post('/decidr/scenario/create', params);
	}

	static async GetScenariosPendingAdvice(params) {
		await  this.autoLogin();
		return this.server().get('/decidr/scenarios', params);
	}

	static async AddChoiceToScenario(params) {
		await  this.autoLogin();
		return this.server().post('/decidr/choice/create', params);
	}

	static async AddVoteToChoice(params) {
		await  this.autoLogin();
		return this.server().post('/decidr/choice/vote', params);
	}

	static async countMetric(metric, value=1) {
		
		this.metric(metric + '.count', value, {}, true/*dontSendToMixpanel*/);

		// For now, just dumps to mixpanel and fakes it (must sum() serverside later) in local metric
		if (mixpanel) {
			if(this.currentUser && this.currentUser.id === HIDE_USER_ID_FROM_MIXPANEL)
				return;
			
			mixpanel.people.increment(metric, value);

			// // special-case spending count for
			// // logging as shown in https://developer.mixpanel.com/docs/javascript#section-tracking-revenue
			// // This metric is currently logged in MarketUtils in BuyItemButton.processPurchaseToken
			// if (metric === 'game.count.dollars_spent') {
			// 	mixpanel.people.track_charge(value);
			// }
		}
	}

	static metric(metric, value, data={}, dontSendToMixpanel=false) {
		(this.metrics || (this.metrics = [])).push({
			// NB user, cat, and level all applied server-side to this item
			// based on the auth token and cat state in the db
			datetime: new Date(),
			epoch:    Date.now(),
			
			metric,
			value,
			data,
		});
		this._touchMetricInterval();

		// Upload to mixpanel as well
		if (mixpanel && !dontSendToMixpanel) {
			if(!this.currentUser || this.currentUser.id !== HIDE_USER_ID_FROM_MIXPANEL) {
				let props;
				if((value !== undefined && value !== null) || Object.keys(data || {}).length > 0) {
					props = { value, ...(data || {})};
				}

				mixpanel.track(metric, props);
			}
		}

		return {
			flush: this._flushMetrics || (this._flushMetrics = this.postMetrics.bind(this)),
		};
	}

	static _touchMetricInterval() {
		if(this._metricInterval)
			return;

		this._metricInterval = setInterval(() => {
			this.postMetrics();
		}, 1000);
	}

	
	static async postMetrics(keepalive=false) {
		return;

		const metrics = (this.metrics || []);
		if(metrics.length > 0) {
			const deviceInfo = await this.deviceInfo();

			// Make a copy and then reset .metrics instead of resetting after tx
			// because the tx is async and doesn't block the rest of the program,
			// so metrics could be added (and then lost) during the tx if we waited
			// to reset after the post finished.
			const batch = metrics.slice();
			this.metrics = [];
			// If not logged in yet, post to an unauth'd route
			const preAuth = ServerStore.currentUser ? '' : '/pre';
			// NB: Not using { autoRetry: true } arg on server.post
			// because we just catch errors and re-buffer the metrics for later posting
			// at the next call of the _metricInterval interval timer
			await server.post('/decidr/metrics' + preAuth, { 
				deviceId: deviceInfo.deviceId, 
				batch
			}, { 
				// Options in this hash passed directly to fetch()
				// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
				// 		The keepalive option can be used to allow the request to outlive the page. 
				// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
				// Since we could be calling postMetrics() in onbeforeonload (or other page-ending circumstances),
				// this ensures that the metrics hit the server.
				// We have to use fetch() instead of sendBeacon() because we need headers
				// to contain our auth data so the correct user is tracked with the metrics as well (if logged in)
				keepalive
			}).catch(error => {
				// Put metrics back on the stack if an error occurred
				this.metrics.unshift(...batch);
				console.warn("Error posting metrics to server:", error);
			});
		}
	}
	
	static async authenticated(authData) {
		// console.log("[authenticated] authData=", authData, typeof(authData), authData.length )
		
		this.authData = authData;
		server.setToken(authData.token);

		// this.currentAccount = authData.account;
		this.currentUser = authData.user;

		// Update GoogleAnalytics with userId
		gtag('set', { 'user_id': this.currentUser.id }); // Set the user ID using signed-in user_id.

		// Update Sentry with user data
		this._setupSentry(authData.user);

		// Update MixPanel with user data
		this._setupMixpanel(authData.user);
		
		// Count this metric
		this.countMetric('app.user.login');

		// Connect the socket.io instance
		this._connectSocket();

		// Notify anyone listening
		this.emit(LOGIN_EVENT, this.currentUser);

		return this;
	}

	static _setupSentry(user) {
		if(!Sentry)
			return;

		Sentry.configureScope(scope => {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			scope.setUser({ email, id: sentryId });
		});
	}

	static async _setupMixpanel(user) {
		if(mixpanel) {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			const deviceInfo = await this.deviceInfo();

			mixpanel.identify(sentryId);
			mixpanel.people.set({ 
				name,
				email,
				deviceBrand: deviceInfo.brand,
				deviceClass: deviceInfo.deviceClass,
			});
		}
	}

	static async linkFb(accessToken) {
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let updatedUser;
		try {
			updatedUser = await server.post('/user/link_fb', { accessToken }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		this._setupSentry(updatedUser);

		return this;
	}

	static async unlinkFb() {
		const deviceInfo = await this.deviceInfo();
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, deviceInfo.deviceId);


		let updatedUser;
		try {
			updatedUser = await server.post('/user/unlink_fb', { deviceInfo }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		
		return this;
	}

	static async login(accessToken) {
		const deviceInfo = await this.deviceInfo();

		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { accessToken, deviceInfo }, { autoRetry: true })
			// console.warn("confirmation is null or confirmation.error:", confirmation);
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {// || confirmation.length) {
			console.warn("Error logging in: ", confirmation);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Token' });

		return this;
	}

	static async anonymousLogin() {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { anonymousLogin: true, deviceInfo });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot anonymousLogin:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Anonymous' });

		return this;
	}
	
	static async tryTakeoverCode(takeoverCode) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { takeoverCode, deviceInfo }, { autoRetry: true });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot tryTakeoverCode:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Takeover' });

		return this;
	}


	static logout() {
		this.authData       = null;
		this.currentUser    = null;
		// this.currentAccount = null;
		server.setToken(null);

		if (this._socket)
			this._socket.disconnect();

		return this;
	}

	static async autoLogin() {
		if(!this.currentUser) {
			if(!await this.attemptAutoLogin()) {
				const res = await this.anonymousLogin();
				if(!res) {
					throw new Error("AutoLogin failed:" + JSON.stringify(res));
				}
			}
		}
	}

	static async attemptAutoLogin(token) {
		if(!token)
			token = window.localStorage.getItem(TOKEN_KEY);
		if(token) {
			return await ServerStore.login(token);
		}

		return false;
	}

	static async deviceInfo() {
		return   this._cachedDeviceInfo ||
				(this._cachedDeviceInfo  = await DeviceInfo.getDeviceInfo());
	}

	static async storePushToken(token) {
		const deviceInfo = await this.deviceInfo();

		// POST to the server
		// No need to await the result, this is a write-only action
		server.post('/user/store_push_token', { deviceInfo, token }, { autoRetry: true });

		// Make return obviously explicit 
		return null;
	}

	static _cachedServerVer;
	/**
	 * Fetches latest build version from server and compares to the version this code was built with
	 *
	 * @static
	 * @returns {object}  {serverVer: string, runningVer: string, needsUpdated: bool}
	 * @memberof ServerStore
	 */
	static async appVersion() {

		const deviceInfo = await this.deviceInfo();

		// Note: We check ver against front end, not API host. 
		// Front end (powered by Netlify) will have the /version.txt, NOT the API server.
		const verCheckHost = this.isPhoneGap ? 'https://decidr.app' : '';

		let versionFetchFailed = false,
			serverBuildTime = null;

		const runningVer     = process.env.REACT_APP_GIT_REV,
			runningBuildTime = buildTime.date;

		const serverVer  =
			this._cachedServerVer ? 
			this._cachedServerVer : 
			this._cachedServerVer = await fetch(verCheckHost + '/version.txt')
				.then(data => {
					serverBuildTime = data.headers.get('last-modified');
					return data.text();
				})
				.then(text => ({
					ver: text.trim().replace("\n", ''),
					buildTime: serverBuildTime
				}))
				.catch(()  => versionFetchFailed = true);
				
		const packet = {
			deviceInfo,
			runningVer,
			runningBuildTime,
			serverVer:       versionFetchFailed ? '(unknown)' : serverVer.ver,
			serverBuildTime: versionFetchFailed ? '(unknown)' : serverVer.buildTime,
			needsUpdated:    versionFetchFailed ? false       : serverVer.ver !== runningVer,
		};

		if(!this._printedVersion && (this._printedVersion = true))
			console.log("[ServerStore.appVersion]", packet);

		return packet;
	}

	static _connectSocket() {
		const socket = io(this.server().urlRoot + '?token=' + this.server().token, {
			// change if there's a different mount point, must match server/app.js 'path:' config for socket.io
			path: '/socket',
			// transports: ['websocket', 'polling'],
		});

		this._socket = socket;

		// // TODO: setup events
		// socket.on('connect', function(socket){
		// 	console.warn("[ServerStore._connectSocket] Socket connected!")
		// });

		// socket.on('test message', function(msg){
		// 	console.log('socket test message: ', msg);
		// 	socket.emit('test message', { thanks: true })
		// });

		// if onSocketEvent called before _connectSocket,
		// handlers accumulate in _socketHandlerBacklog
		if (this._socketHandlerBacklog) {
			this._socketHandlerBacklog.forEach(({ event, callback }) => {
				socket.on(event, callback);
			})
		}
	}

	// For external modules to listen for updates
	static socket() {
		return this._socket;
	}

	static onSocketEvent(event, callback) {
		if (!this._socket) {
			if(!this._socketHandlerBacklog)
				this._socketHandlerBacklog = [];
			this._socketHandlerBacklog.push({ event, callback });
		} else {
			this._socket.on(event, callback);
		}
	}

	// Define this just for parity
	static offSocketEvent(event, callback) {
		this._socket.off(event, callback);
	}
}

window.store = ServerStore;
