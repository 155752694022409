import React from 'react';

import styles from './AdvicePage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import TextareaAutosize from 'react-autosize-textarea';

import { ServerStore } from 'utils/ServerStore';
import { useRemoteData, remoteDataCache } from 'utils/useRemoteData';

import LoadingSpinner from 'components/LoadingSpinner';

// Simple utility to take "foo/bar/baz" and make it "foo/bar"
const backUrl = (url="") => url.split('/').slice(0,-1).join('/');

export default function AdvicePage({ match, ...props }) {
	return (<>
		<Route exact path={match.path} component={AdvicePageHome}/>
		<Route path={`${match.path}/request`} component={AdvicePageRequest}/>
		<Route path={`${match.path}/scenario/:scenarioId`} component={AdvicePageScenario}/>
	</>);
}

function AdvicePageRequest({ match, ...props }) {
	const [vars, setVars] = React.useState({});

	const selectOpt = async opt => {
		if(!opt) {
			// opt = prompt("What do you want to improve?");
			opt = (await window.Alert({
				title: 'What do you want to improve?',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'true'
				},
				showCancelButton: true,
				confirmButtonText: 'Add Option',
			})).value;
		}

		if(opt) {
			setVars({ ...vars, opt, submitted: true });
			// alert("Selected "+ opt + " for " + vars.sentence);

			const scenario = await ServerStore.CreateScenario({
				name: vars.sentence,
				targetGoal: opt
			});

			console.log("[AdviceRequestPage] created scenario:", scenario);

			//
			// TODO: If this is first scenario, explain to the user what to expect
			//

			// Remove cached data before sending back to advice page
			if (remoteDataCache.GetUserAdviceList)
				remoteDataCache.GetUserAdviceList.cacheDirty = true;

			// Send back to advice home page
			setTimeout(() => 
				props.history.push(backUrl(match.url)), 1);
			
		}
	}

	const sentenceDoneHandler = () => {
		const sentence   = vars.sentence ? vars.sentence.trim() : '',
			words        = sentence.split(/\s+/),
			sentenceDone = words.length > 1;

		setVars({
			...vars,
			sentenceDone,
			error: !sentenceDone ? 
				`At least 2 words are required` : 
				false,
		})
	};

	let textArea;
	React.useEffect(() => {
		if (textArea) {
			textArea.focus();
		}
	});

	return (<>
		<SubPageLayout title="Get Advice - Decidr">
			<div className={styles.requestRoot}>

				<Link to={backUrl(match.url)}><FontAwesomeIcon icon={faArrowLeft}/></Link>

				{!vars.submitted ? <>

					<h1>New Scenario</h1>

					{!vars.sentenceDone ? 
						<>
							<p>
								What do you need advice on in a single sentence, no commas or "and"s?
							</p>

							<TextareaAutosize
								className={styles.requestInput}
								type="text"
								ref={el => textArea = el}
								placeholder="Type Here"
								defaultValue={vars.sentence}
								onKeyPress={evt => evt.which === 13 && sentenceDoneHandler() }
								onChange={evt => setVars({
									...vars,
									sentence: evt.target.value
								})}
							/>
							{vars.error && <div className={styles.errorMsg}>{vars.error}</div>}

							<div onClick={sentenceDoneHandler}
								className={styles.request}>
								<FontAwesomeIcon icon={faChevronRight}/>
							</div>
						</>
						:
						<>
							<p>
								<i>{vars.sentence}</i>
							</p>
					
							<p>
								What do you want to improve?
							</p>
							
							<div className={styles.optionListRoot}>
								<div className={styles.optionRoot} onClick={x => selectOpt('Peace')}>
									Peace
									<FontAwesomeIcon icon={faChevronRight}/>
								</div>
								<div className={styles.optionRoot} onClick={x => selectOpt('Happiness')}>
									Happiness
									<FontAwesomeIcon icon={faChevronRight}/>
								</div>
								<div className={styles.optionRoot} onClick={x => selectOpt('Less Stress')}>
									Less Stress
									<FontAwesomeIcon icon={faChevronRight}/>
								</div>
								<div className={styles.optionRoot} onClick={x => selectOpt('Acquire Information')}>
									Acquire Information
									<FontAwesomeIcon icon={faChevronRight}/>
								</div>
								<div className={styles.optionRoot} onClick={x => selectOpt(null)}>
									<i>Add something else...</i>
									<FontAwesomeIcon icon={faPlus}/>
								</div>
							</div>
						</>
					}
				</> 
				:
				<>
					{/* <h1>Saving<br/><i>{vars.sentence}</i></h1> */}

					<center>
						<LoadingSpinner/>
					</center>
				</>}
			</div>

		</SubPageLayout>
	</>);
}


function AdvicePageScenario({ match, ...props }) {

	const { scenarioId } = match.params,
		scenario = useRemoteData(
			() => ServerStore.getScenario(scenarioId),
			'GetScenario:' + scenarioId
		);

	// console.log(match);
	return (<>

		<SubPageLayout title="Get Advice - Decidr">
			<div className={styles.scenarioRoot}>
	
				<Link to={'/advice'}><FontAwesomeIcon icon={faArrowLeft}/></Link>

				{!scenario.loadDone ? 
					<center>
						<LoadingSpinner/>
					</center>
				: <>

					{/* <h1>Scenario: [{match.params.scenarioId}]</h1> */}
					<h1>{scenario.name}</h1>

					<div className={styles.target}>
						<div className={styles.label}>You want to improve:</div>
						<div className={styles.value}>{scenario.targetGoal}</div>
					</div>
				</>}

			</div>

		</SubPageLayout>
	</>);
}


function AdvicePageHome({ match, ...props }) {

	const advice = useRemoteData(
		() => ServerStore.GetUserAdviceList(),
		'GetUserAdviceList'
	);

	console.log("[AdvicePageHome] got advice:", advice);

	// Advice loaded but empty? Send to "request" endpoint
	if(!advice.length && advice.loadDone) {
		// setTimeout(() => 
		// 		props.history.push(`${match.url}/request`), 1);

		// Render request page directly so back btn in request
		// page works as expected
		return <AdvicePageRequest match={match} {...props}/>
	}

	return (<>
		<SubPageLayout title="Get Advice - Decidr">
			<div className={styles.root}>

				<Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link>

				<h1>Get Advice</h1>

				<Link to={`${match.url}/request`}
					className={styles.request}>
					<FontAwesomeIcon icon={faPlus}/>
				</Link>

				<MyScenarioList advice={advice}/>

			</div>

		</SubPageLayout>
	</>);
}



function MyScenarioList({ advice, ...props }) {
	if(!advice.loadDone) {
		return <center>
			<LoadingSpinner/>
		</center>;
	}

	if(!Array.isArray(advice))
		advice = Array.from(advice || []);

	return (<>
		<div className={styles.optionListRoot}>
			{advice.map(item =>
				<MyScenarioItem key={item.id} item={item}/>
			)}
			{/* <MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/>
			<MyScenarioItem/> */}
		</div>
	</>)
}

function MyScenarioItem({ item, ...props }) {
	return (<>
		<Link to={`/advice/scenario/${item.id}`} className={styles.optionRoot}>
			<div className={styles.title}>
				{item.name}
			</div>
			{/* <div className={styles.status}>
				{item.statusTitle}
			</div> */}
		</Link>
	</>)
}